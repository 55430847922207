import { ChevronRight } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useMemo } from 'react'
import {
	Card,
	CardDescription,
	CardFooter,
	CardTitle,
} from '~/components/ui/card'
import { Skeleton } from '~/components/ui/skeleton'
import { useDescribe } from '~/hooks/admin-api/use-describe.hook'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'

export const AccountsRecap = () => {
	const { t } = useTranslation()
	const accountsDescribe = useDescribe('accounts')

	const accounts = useListEntityEntries(
		'accounts',
		{
			page_size: 1,
			// @ts-expect-error
			status: 'active',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const accountsBusiness = useListEntityEntries(
		'accounts',
		{
			page_size: 1,
			// @ts-expect-error
			type: 'business',
			status: 'active',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const accountsPersonal = useListEntityEntries(
		'accounts',
		{
			page_size: 1,
			// @ts-expect-error
			type: 'personal',
			status: 'active',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const accountsPlatform = useListEntityEntries(
		'accounts',
		{
			page_size: 1,
			// @ts-expect-error
			type: 'platform',
			status: 'active',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const accountsStatuses = useMemo(() => {
		const filter = accountsDescribe.data?.filters.find(
			({ name }) => name === 'type',
		)

		if (!filter) return []

		const values = {
			business: accountsBusiness.data?.meta?.count || 0,
			personal: accountsPersonal.data?.meta?.count || 0,
			platform: accountsPlatform.data?.meta?.count || 0,
		}

		return (
			(filter.values as string[])
				?.map((name) => ({
					name,
					value: values[name as keyof typeof values] || 0,
				}))
				.filter(({ value }) => !!value) || []
		)
	}, [
		accountsDescribe.data?.filters,
		accountsBusiness.data,
		accountsPersonal.data,
		accountsPlatform.data,
	])

	return (
		<Card className='flex flex-col justify-center text-left'>
			<div className='flex'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Comptes actifs</CardTitle>

					<CardDescription>Liste des types de comptes.</CardDescription>
				</div>

				<div className='flex min-w-[100px]'>
					<div className='relative z-30 flex flex-1 flex-col justify-center gap-1 border-t border-l px-6 py-4 text-left sm:border-t-0'>
						<span className='text-muted-foreground text-xs'>Total</span>

						<span className='flex flex-row items-end gap-1 font-bold text-lg leading-none sm:text-3xl'>
							{accounts.isLoading ? (
								<Skeleton className='h-6 w-full' />
							) : (
								t('common:numbers.base', {
									val: accounts.data?.meta?.count || 0,
								})
							)}
						</span>
					</div>
				</div>
			</div>

			<div className='flex flex-col divide-y border-y'>
				{accountsStatuses.map(({ name, value }) => (
					<Link
						key={name}
						href={`/accounts?status=${name}`}
						className='flex items-center justify-between gap-2 px-6 py-2 text-sm'
					>
						<span className='flex-1'>
							{t([`values:values.status.${name}`, name])}
						</span>

						<span>{t('common:numbers.base', { val: value })}</span>

						<span>
							<ChevronRight className='size-4' />
						</span>
					</Link>
				))}
			</div>

			<CardFooter className='flex justify-end py-3 pb-3'>
				<Link
					href='/accounts'
					className='flex items-center text-primary text-sm leading-none'
				>
					Voir les comptes
					<ChevronRight className='ml-2 size-4' />
				</Link>
			</CardFooter>
		</Card>
	)
}
