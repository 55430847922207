import type {
	ActionPage,
	Describe,
	FieldDescription,
	FieldType,
} from '@cocoonspace/types/admin/describe'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getAttributeColSize } from '~/domains/describes/utils/get-attribute-col-size'
import { useAdminApi } from '~/lib/sdk-admin-js'

export type UiFieldDescription = Omit<
	FieldDescription,
	'editable' | 'items'
> & {
	editable: boolean
	items?: {
		type?: FieldType
		fields: UiFieldDescription[]
	}
	linkType?: string
	asyncConfig?: {
		params: any
	}
}

export type UiDescribe = Omit<Describe, 'attributes' | 'filters'> & {
	filters: UiFieldDescription[]
	attributes: UiFieldDescription[]
}

export const convertEditable = (
	attributes: FieldDescription[],
	actionFilter?: ActionPage,
) =>
	attributes.map(({ items, ...attr }) => {
		const newAttr: UiFieldDescription = {
			...attr,
			editable: actionFilter ? attr.editable?.includes(actionFilter) : true,
		}

		if (items) {
			newAttr.items = {
				...items,
				...(items.fields && {
					fields: convertEditable(items.fields, actionFilter),
				}),
			}
		}

		return newAttr
	})

/**
 * Retrieve Entity describe infos
 * @param describeId
 */
export const useDescribe = (
	describeId?: string,
	actionFilter?: ActionPage,
	queryOpts?: { initialData: { data: Describe } },
) => {
	const $adminApi = useAdminApi()

	const { data, ...queryRes } = useQuery({
		queryKey: ['describe', describeId],
		queryFn: () =>
			$adminApi
				.getModuleDescribe({ uriParams: { describeId: describeId! } })
				.then((res) => res.data),
		enabled: !!describeId,
		cacheTime: 100 * 1000 * 60,
		staleTime: 50 * 1000 * 60, // Does not refetch for the next 50 min
		...(queryOpts?.initialData && {
			initialData: queryOpts.initialData,
		}),
	})

	const describe = useMemo(() => {
		if (data) {
			const attributes = data.data.attributes
				.filter((attr) =>
					actionFilter ? !attr.hidden?.includes(actionFilter) : true,
				)
				.map((attr) => {
					if (attr.items?.fields) {
						attr.items.fields = attr.items.fields.filter((attr) =>
							actionFilter ? !attr.hidden?.includes(actionFilter) : true,
						)
					}

					return attr
				})

			return {
				...data.data,
				filters: data.data.filters ? convertEditable(data.data.filters) : [],
				attributes: convertEditable(attributes, actionFilter).map((attr) => ({
					...attr,
					cols: getAttributeColSize(attr),
				})),
			}
		} else {
			return undefined
		}
	}, [data, actionFilter])

	return {
		...queryRes,
		data: describe,
	}
}
