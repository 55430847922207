import { ChevronRight } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useMemo } from 'react'
import {
	Card,
	CardDescription,
	CardFooter,
	CardTitle,
} from '~/components/ui/card'
import { Skeleton } from '~/components/ui/skeleton'
import { useDescribe } from '~/hooks/admin-api/use-describe.hook'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'

export const UsersRecap = () => {
	const { t } = useTranslation()
	const usersDescribe = useDescribe('users')

	const users = useListEntityEntries(
		'users',
		{ page_size: 1 },
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const usersActive = useListEntityEntries(
		'users',
		{
			page_size: 1,
			// @ts-expect-error
			status: 'active',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const usersSuspended = useListEntityEntries(
		'users',
		{
			page_size: 1,
			// @ts-expect-error
			status: 'suspended',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const usersDeleted = useListEntityEntries(
		'users',
		{
			page_size: 1,
			// @ts-expect-error
			status: 'deleted',
		},
		{ query: { cacheTime: 100 * 1000 * 60, staleTime: 50 * 1000 * 60 } },
	)

	const usersStatuses = useMemo(() => {
		const filter = usersDescribe.data?.filters.find(
			({ name }) => name === 'status',
		)

		if (!filter) return []

		const values = {
			active: usersActive.data?.meta?.count || 0,
			suspended: usersSuspended.data?.meta?.count || 0,
			deleted: usersDeleted.data?.meta?.count || 0,
		}

		return (
			(filter.values as string[])
				?.map((name) => ({
					name,
					value: values[name as keyof typeof values] || 0,
				}))
				.filter(({ value }) => !!value) || []
		)
	}, [
		usersDescribe.data?.filters,
		usersActive.data,
		usersSuspended.data,
		usersDeleted.data,
	])

	return (
		<Card className='flex flex-col justify-center text-left'>
			<div className='flex'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Users</CardTitle>

					<CardDescription>Liste des statuts utilisateurs.</CardDescription>
				</div>

				<div className='flex min-w-[100px]'>
					<div className='relative z-30 flex flex-1 flex-col justify-center gap-1 border-t border-l px-6 py-4 text-left sm:border-t-0'>
						<span className='text-muted-foreground text-xs'>Total</span>

						<span className='flex flex-row items-end gap-1 font-bold text-lg leading-none sm:text-3xl'>
							{users.isLoading ? (
								<Skeleton className='h-6 w-full' />
							) : (
								t('common:numbers.base', { val: users.data?.meta?.count || 0 })
							)}
						</span>
					</div>
				</div>
			</div>

			<div className='flex flex-col divide-y border-y'>
				{usersStatuses.map(({ name, value }) => (
					<Link
						key={name}
						href={`/users?status=${name}`}
						className='flex items-center justify-between gap-2 px-6 py-2 text-sm'
					>
						<span className='flex-1'>{t(`values:values.status.${name}`)}</span>

						<span>{t('common:numbers.base', { val: value })}</span>

						<span>
							<ChevronRight className='size-4' />
						</span>
					</Link>
				))}
			</div>

			<CardFooter className='flex justify-end py-3 pb-3'>
				<Link
					href='/users'
					className='flex items-center text-primary text-sm leading-none'
				>
					Voir les utilisateurs
					<ChevronRight className='ml-2 size-4' />
				</Link>
			</CardFooter>
		</Card>
	)
}
