import { Menu } from 'lucide-react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Sidebar } from '~/components/organisms/sidebar'
import { Button } from '~/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '~/components/ui/sheet'

export const AppMenu = () => {
	const router = useRouter()
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		setIsOpen(false)
	}, [router.asPath])

	return (
		<Sheet open={isOpen} onOpenChange={setIsOpen}>
			<SheetTrigger asChild>
				<Button data-testid='toggle-sidebar-btn' size='icon' variant='ghost'>
					<Menu size={20} />
				</Button>
			</SheetTrigger>

			<SheetContent
				side='left'
				className='flex w-[180px] flex-col bg-slate-50 p-0'
			>
				<Sidebar />
			</SheetContent>
		</Sheet>
	)
}
